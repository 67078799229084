<template>
    <div>
		<!-- End Top Title -->
		<section class="template-title has-over mtx" style="margin-top: 150px">
				<div class="container">
					<h3 class="title">PACKAGE</h3>
					<span class="title_over">PACKAGE</span>
					<p class="subtitle">Explore some of the best tips from around the world</p>
				</div>
			</section>

        <section class="customs-single ver-1">
			<div class="container">
				<div class="row">
					<div class="col-md-8">
						<article class="block-customs-single ver-1">
							<!-- <div class="featured-customs">
								<div class="owl-carousel">
									<div class="images">
										<img src="images/services/gallery/01.jpg" alt="">
									</div>
									
								</div>
							</div> -->

                            <VueSlickCarousel v-bind="settings">
                                <img v-for="(img, index) in packageDetails.packageImage"
                                    :key="index"
                                    :src="img.imageUrl" alt="">
                            </VueSlickCarousel>

							<div class="entry-customs">
                                <h2 class="mt-5 mb-4">{{ packageDetails.packageName}}</h2>
								<div class="entry-box">
									<h3>About Package</h3>
									<p>{{packageDetails.description}}</p>
								</div>
							</div>
						</article>
					</div>
					<div class="col-md-4">
						<div class="sidebar">
							<div class="widget widget-category">
								<h3>Event's</h3>
								<ul>
								    <li v-for="(activity, index) in packageDetails.activities" :key="index">
								    	<img src="images/icon/next.png" alt=""> {{activity}}
								    </li>
								</ul>
							</div>
                            <div class="widget-infomation">
								<ul>
                                    <li>
										<div class="text">
											<h5>{{packageDetails.totalPerson}} Person's</h5>
											
										</div>
										<div class="icon">
											<img src="images/icon/author.png" alt="">
										</div>
									</li>
									<li>
										<div class="text">
											<h5>{{packageDetails.tourLength}} </h5>
										</div>
										<div class="icon">
											<img src="images/icon/clock.png" alt="">
										</div>
									</li>									
								</ul>
                            </div>
							<div class="widget-infomation">
								<ul>
                                    <li class="cars" v-for="(car, index) in packageDetails.cars" :key="index">
                                        <input type="radio" name="carSelect" :value="car.carId._id" v-model="selectedCar"/>
                                        <img class="ml-3 mr-3" width="35%" :src="car.carId.carImage[0].imageUrl"/>
                                        <div class="">
                                            <label class="d-block">{{ car.carId.carType }}</label>
                                            <label class="d-block">$ {{ car.price }}</label>
                                        </div>
                                    </li>

                                    <li class="download">
                                    </li>
								</ul>
                                <div class="form-dates">
                                    <div class="pick-date width2">
                                        <date-picker
                                        v-model="pickUpDate"
                                            format="YYYY-MM-DD"
                                            type="date"
                                            value-type="MM/DD/YYYY"
                                            placeholder="Select date"
                                            :disabled-date="$disabledBeforeToday"
                                        ></date-picker>
                                    </div>
                                    <div class="pick-time js width2">
                                        <date-picker
                                            v-model="pickUpTime"
                                            format="hh:mm A"
                                            type="time"
                                            :minute-step="15"
                                            value-type="hh:mm A"
                                            placeholder="Select Time"
                                            :open.sync="openOnewayTimer"
                                            @change="closeTimer"
                                        ></date-picker>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" class="float-right mt-3" @click="validateForm()">
                                        Book Now
                                    </button>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
</template>


<script>
import { mapGetters } from "vuex";
import { request } from "../../utils/api";
import VueSlickCarousel from 'vue-slick-carousel'
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: { VueSlickCarousel, DatePicker, },
    data(){
        return {
            packageDetails: [],
            selectedCar: '',
            packageId: this.$route.params.packageId || '',
            pickUpDate: '',
            pickUpTime: '',
            openOnewayTimer: false,
            settings: {
				"autoplay": true,
				"edgeFriction": 0.35,
				"fade": true,
  				"infinite": true,
				"speed": 500,
				"slidesToShow": 1,
				"cssEase": "linear",
				"slidesToScroll": 1
			}
        }
    },
    computed:{
		...mapGetters(['isLoggedIn']), 
	},
    methods:{
        async getPackage(){
            const resp = await request({
                method: "get",
                url: `/api/v1/cars/packageDetail/${this.packageId}`,
            });
			console.log(resp)
            if (resp.remote === "success") {
                this.packageDetails = resp.data;
            }else{
                this.error = resp.errors
            }
        },
        closeTimer(value, type) {
            if (type === 'ampm') {
                this.openOnewayTimer = false;
            }
        },
        validateForm(){
            if(this.selectedCar == ''){
                this.$limotoast.warning('Select your vehicle');
                return;
            }

            if(this.pickUpDate == ""){
                this.$limotoast.warning('Select Pick Up Date');
                return;
            }

            if(this.pickUpTime == ""){
                this.$limotoast.warning('Select Pick Up Time');
                return;
            }

            var pickupDateTime = `${this.pickUpDate} ${this.pickUpTime}`;
            if(this.$moment().isAfter(this.$moment(pickupDateTime))){
                this.$limotoast.warning('Please select future time!');
                return false;
            }
            var resultInMinutes = this.$getTimeDifferenceInMinute(pickupDateTime, new Date());
            if (resultInMinutes < 91) {
                this.$limotoast.warning('You Can Only Book After 90 minutes From Current Time!');
                return false;
            }

            const packageCar = this.packageDetails.cars.find((o) => o.carId._id === this.selectedCar)

            const packageForm = {
                pickUpTime: this.pickUpTime,
                pickUpDate: this.pickUpDate,
                selectedCar: this.selectedCar,
                packageId: this.packageId,
                packageName: this.packageDetails.packageName,
                car: packageCar,
                price: packageCar.price,
                tCost: packageCar.price,
            };
            this.$store.commit('setPackageDetails', packageForm);
            
            if (this.isLoggedIn) {
                this.$router.push({ name: "package-payment" });
            } else {
                this.$router.push({
                name: "login",
                query: { redirectUrl: 'package-payment' },
                });
            }

        },
    },
    mounted(){
        console.log('ss', this.$router.history.current.name)
        this.getPackage()
    }
}
</script>

<style scoped>
    .sidebar{
        width: 100% !important
    }
    .widget-infomation ul li.cars:hover {
        background-color: #bf9c60;
    }
    .form-dates{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .mx-datepicker{
        width: 100%;
    }
    .form-dates ::v-deep .mx-input-wrapper input, .form-dates ::v-deep .mx-input-wrapper i, .form-dates ::v-deep .summary-bar input{
        color: #333;
    }
</style>
<style>
.mx-datepicker-popup{
    overflow: hidden !important;
}
</style>