<template>
    <section class="check-out-area">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="check-out">
                        <div class="top">
                            <div class="thanks">
                                Review Your Package
                            </div>
                        </div>
                        <div class="middle">
                            <h2>Package: {{package.packageName}}</h2>
                            <ul class="summary-bar">
                                <li>
                                    <div class="info">
                                        Pick Up Date
                                    </div>
                                    <p>{{package.pickUpDate}}</p>
                                </li>
                                <li>
                                    <div class="info">
                                        Pick Up Time
                                    </div>
                                    <p>{{package.pickUpTime}}</p>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="bottom">
                            <h2>SELECTED CAR</h2>
                            <p>{{package.car.carId.carType}}</p>
                            <div class="car-choose">
                                <VueSlickCarousel v-bind="settings">
                                    <img v-for="(img, index) in package.car.carId.carImage"
                                        :key="index"
                                        :src="img.imageUrl" alt="">
                                </VueSlickCarousel>
                            </div>
                            <p>Price - ${{ package.tCost }}</p>
                        </div>
                        
                        <div class="discountBanner" v-if="isExtraDiscount" v-html="extraDiscountText"></div>

                        <div class="action pt-5 pb-5">
                            <input type="checkbox" v-model="terms"/> I accepted all terms & condition <a href="#/terms" target="_black">Terms</a>
                            <br/>
                        </div>
                        <button :disabled="!terms" type="button" @click="confirmRide()"> Confirm Your Ride</button>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "../../utils/api";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: { VueSlickCarousel },
    data(){
        return {
            isExtraDiscount: false,
            pickUpDate: '',
            pickUpTime: '',
            terms: false,
            totalTrips: '',
            env: process.env,
            priceWithTip: '',
            discountValue: '',
            settings: {
				"autoplay": true,
				"edgeFriction": 0.35,
				"fade": true,
  				"infinite": true,
				"speed": 500,
				"slidesToShow": 1,
				"cssEase": "linear",
				"slidesToScroll": 1
			}
        }
    },
    computed:{
		...mapGetters(['package', 'booking', 'coupon', 'isLoggedIn', 'userId', 'userToken']),
        
	},
    methods:{
        async confirmRide () {
            const { packageId, pickUpDate, pickUpTime, selectedCar, price, tCost } = this.package;
            const { cardId} = this.booking;

            const bookingInfo = {
                carTypeRequired : selectedCar,
                paymentMethod : "Stripe",
                paymentSourceRefNo : cardId,
                scheduleTimezone : "America/Los_Angeles",
                scheduleDate: pickUpDate,
                scheduleTime: pickUpTime,
                packageId,
                price,
                tCost
            }


            bookingInfo.isExtraDiscount = this.isExtraDiscount;
            if(this.isExtraDiscount){
                bookingInfo.discount = {
                    discountPrice: this.discountPrice,
                    totalTrips: this.totalTrips,
                    discountValue: this.discountValue
                }
            }


            const resp = await request({
                method:'POST',
                url: '/api/v1/trip/addPackageTrip',
                data: bookingInfo,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            });
            if(resp.remote == 'success'){
                this.$limotoast.success('Trip added!');
                this.$router.push({name: 'thanks'});
                this.$store.commit('clearBooking');
            }else{message
                this.$limotoast.error(resp.errors);
            }
        },
        verifyDiscount(){
            if(this.totalTrips === 0){
                let price = this.package.car.price;
                let dis = (price * 10) / 100;
                
                this.discountPrice = Math.round(price - parseFloat(dis));
                
                this.isExtraDiscount = true;
                this.extraDiscountText = `You got 10% discount on your first Ride. You are going to pay <span class="line"> $${price} </span> $${this.discountPrice}`
                this.discountValue = '10%';

                this.package.tCost = this.discountPrice
                this.$store.commit("setPackageDetails", this.package);
            }
        }
    },
    mounted(){
        if(!this.isLoggedIn){
            this.$router.push({name: 'login'})
            return false;
        }
        if(!this.package.packageId){
            this.$router.push({name: 'packages'})
            return;
        }

        this.totalTrips = this.package.userTrips;
        this.verifyDiscount()

        if(this.coupon){
            this.couponCode = this.coupon.coupon;
        }
    },
}
</script>

<style scoped>
.check-out .middle{
    padding: 70px 100px;
}
.stripForm iframe{
    height: 45px !important;
}
.check-out-area{
    padding-top: 0 !important;
}
.car-choose{
    width: 20%;
    margin: auto;
}
.couponForm input{
    width: 20%;
    display: inline-block;
}

</style>
<style>

.line{
  text-decoration: line-through;
}
</style>
