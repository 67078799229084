<template>
    <div>
		<!-- End Top Title -->
		<section class="template-title has-over mtx" >
				<div class="container">
					<h3 class="title">PACKAGES</h3>
					<span class="title_over">PACKAGES</span>
					<p class="subtitle">Explore some of the best tips from around the world</p>
				</div>
			</section>
		<!-- Start Services Ratea -->
		<section class="blog-slider">
			<div class="container" v-if="packageList.length">
				<div v-for="(item, index) in packageList" :key="index" class="col-lg-4 d-inline-block">
					<article class="post">
						<router-link :to="{name: 'package-details', params: {packageId: item._id}}">
							<div class="featured-image">
								<img :src="item.packageImage[0].imageUrl" :alt="item.packageName">
							</div>							
							<div class="entry-content">
								<div class="entry-post-title">
									<h4 class="post-title">{{item.packageName}}</h4>
								</div>
								<ul>
									<li class="author">
										<img src="images/icon/author.png" alt="">{{item.totalPerson}}
									</li>
									<li class="date">
										<img src="images/icon/clock.png" alt="">{{item.tourLength}}
									</li>
								</ul>
							</div>
						</router-link>
					</article>
				</div>
			</div>
			<div class="container" v-else>
				<h1 class="text-center">Coming Soon</h1>
			</div>
		</section>
    </div>
</template>


<script>
import { request } from "../../utils/api";

export default {
    data(){
        return {
            packageList: []
        }
    },
    methods:{
        async getPackages(){
            const resp = await request({
                method: "get",
                url: `/api/v1/cars/packages`,
            });
			
            if (resp.data.remote === "success") {
                this.packageList = resp.data.data;
            }else{
                this.error = resp.errors
            }
        },
    },
    mounted(){
        this.getPackages()
    }
}
</script>

<style>

</style>