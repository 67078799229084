<template>
    <div>
        <section class="template-title has-over mtx" style="margin-top: 150px">
            <div class="container">
                <h3 class="title">PAYMENT</h3>
                <span class="title_over">PAYMENT</span>
                <p class="subtitle"></p>
            </div>
        </section>
        <section class="card-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="form-card" v-show="toggle">
                            <form @submit.prevent method="get" accept-charset="utf-8" class="stripForm">
                                <div class="row">
                                    <div class="one-half card-number">
                                        <label for="card-number">Card number </label>
                                        <div id="card-number" class="Adsadsa"></div>
                                    </div>
                                    <div class="one-half date">
                                        <label for="month">Expiration date</label>
                                        <div class="one-half">
                                            <div id="card-expiry"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="one-half cvv">
                                        <label for="cvv">cvv</label>
                                        <div id="card-cvc"></div>
                                    </div>
                                    <div class="clearfix"></div>
                                    
                                    <div id="card-error"></div>

                                    <div class="col-md-12">
                                        <div class="btn-submit">
                                            <button type="button" class="ml-2" @click="showCardBlock()">Cancel</button>
                                            <button type="submit" @click="createToken()">Continue</button>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="tables-item mt-5" v-show="!toggle">
                            <table class="border">
                                <thead class="bg-2">
                                    <tr>
                                        <th>#</th>
                                        <th>Card Type</th>
                                        <th>Card No.</th>
                                        <th>Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody class="border">
                                    <tr v-for="(item, index) in cardList" :key="index" v-if="cardList.length > 0">
                                        <td>
                                            <input type="radio" name="" v-model="selectedCard" :value="item._id"/>
                                        </td>
                                        <td>{{item.name}}</td>
                                        <td>**** {{item.lastd}}</td>
                                        <td>{{item.lastUsed}}</td>
                                        <td><a href="javascript:void(0)" @click="removeCard(item._id)"><i class="fa fa-close"></i></a></td>
                                    </tr>
                                    <tr else>
                                        <td style="text-align: center;padding-left: 0" colspan="5">
                                            <button type="button" @click="showCardBlock()"> {{cardList.length ? 'Add New Card' : 'Add Your First Card'}}</button>
                                            <button v-if="selectedCard" class="ml-2" type="button" @click="goToCheckout()">Checkout</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="text-center"> you will be able to review your ride on the next page before confirming your reservation. Your have to select the card or add new card.</p>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "../../utils/api";
import { StripeElementCard } from '@vue-stripe/vue-stripe';

export default {
    components: {
        StripeElementCard,
    },
    data(){
        return {
            token: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            cardList: [],
            toggle: false,
            selectedCard: '',
            error: '',
            userTotalTrip: 0
        }
    },
    computed:{
		...mapGetters(['package', 'isLoggedIn', 'userId', 'userToken']),
        stripeElements () {
            return this.$stripe.elements();
        },
	},
    methods:{
        async createToken () {
            const { token, error } = await this.$stripe.createToken(this.cardNumber);
            if (error) {
                // handle error here
                document.getElementById('card-error').innerHTML = error.message;
                return;
            }
            
            this.addCard(token)
            // handle the token
            // send it to your server
        },
        async addCard(cardDetails){
			const jsonDataPass = {
                customerId: this.userId,
                type: "Card",  
                name: cardDetails.card.brand,
                lastd: cardDetails.card.last4,
                token: cardDetails.id,
                detials: "Payment Details"
            };
			const resp = await request({
				method:'POST',
				url: '/api/v1/payment/addpos',
				data: jsonDataPass,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
			});
            
            if(resp.remote == 'success'){
                this.$limotoast.success('Card saved!')
                this.getUserSavedCards();
                this.showCardBlock()
            }else{
                console.log(resp.errors)
                this.error = resp.errors

                this.$limotoast.error(resp.errors)
            }
		},

        async getUserSavedCards(){
            const resp = await request({
				method:'GET',
				url: '/api/v1/payment/poslistwithusertotaltrip',
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
			});
            console.log('resp', resp)
            if(resp.remote == 'success'){
                this.cardList = resp.data.data;
                this.userTotalTrip = resp.data.totalTrips;
            }
        },
        showCardBlock(){
            this.toggle = !this.toggle;
        },
        async removeCard(id){
            if(window.confirm('Are you sure to remove the payment method?')){
                this.selectedCard = '';
                const resp = await request({
                    method:'POST',
                    url: '/api/v1/payment/deletepos',
                    data: {
                        _id: id
                    },
                    headers: {
                        authorization: this.userToken,
                        customerid: this.userId
                    }
                });
                
                if(resp.remote == 'success'){
                    this.$limotoast.success('Card deleted successfully!')
                    this.getUserSavedCards();
                }else{
                    console.log(resp.errors)
                    this.error = resp.errors
                }
            }
        },
        goToCheckout(){
            if(this.selectedCard == ''){
                this.$limotoast.warning('Please select card.')
                return;
            }
            this.$store.commit('addUserTripInPackage', this.userTotalTrip)
            this.$store.commit('setPayment', this.selectedCard);
            this.$router.push({'name': 'package-overview'});
        }
    },
    mounted(){
        if(!this.package.packageId){
            this.$router.push({name: 'packages'})
            return;
        }

        this.getUserSavedCards();
        
        const style = {
            base: {
                color: 'black',
                height: '50px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };
        
        setTimeout(() => {
            this.cardNumber = this.stripeElements.create('cardNumber', { style });
            this.cardNumber.mount('#card-number');
            this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
            this.cardExpiry.mount('#card-expiry');
            this.cardCvc = this.stripeElements.create('cardCvc', { style });
            this.cardCvc.mount('#card-cvc');
        }, 2500);
    },
    beforeDestroy () {
        this.cardNumber.destroy();
        this.cardExpiry.destroy();
        this.cardCvc.destroy();
    },
}
</script>

<style scoped>
.stripForm iframe{
    height: 45px !important;
}

.card-area{
    padding-top: 0;
}

</style>