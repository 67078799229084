<template>
    <div class="top-gap">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
	
}
</script>

<style>
.top-gap{
    margin-top: 150px
}
</style>